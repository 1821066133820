<!-- Footer Start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
        <a href="#" class="logo-footer">
          <img src="assets/images/logo-light.png" height="32" alt="" />
        </a>
        <p class="mt-4">
          You can’t connect the dots looking forward; <br />  
          you can only connect them looking backward. <br />
          So you have to trust that the dots will somehow connect in your future. <br />
          You have to trust in something — your gut, destiny, life, karma, whatever. <br />
          <br />
          - Steve Jobs
        </p>
        <ul class="list-unstyled social-icon social mb-0 mt-4">
          <li class="list-inline-item" *ngIf="false">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="facebook"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1" *ngIf="false">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="instagram"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="linkedin"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="github"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="twitter"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
        </ul>
        <!--end icon-->
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h4 class="text-light footer-head">Sections</h4>
        <ul class="list-unstyled footer-list mt-4">
          <li>
            <a routerLink="/" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Home</a
            >
          </li>
          <li>
            <a routerLink="/blog" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Blog</a
            >
          </li>
          <li>
            <a routerLink="/aboutme" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> About me</a
            >
          </li>
        </ul>
      </div>
      <!--end col-->

      <!-- <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h4 class="text-light footer-head">Usefull Links</h4>
        <ul class="list-unstyled footer-list mt-4">
          <li>
            <a routerLink="/page-terms" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Terms of Services</a
            >
          </li>
          <li>
            <a routerLink="/page-privacy" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Privacy Policy</a
            >
          </li>
          <li>
            <a routerLink="/documentation" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Documentation</a
            >
          </li>
          <li>
            <a routerLink="/changelog" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Changelog</a
            >
          </li>
          <li>
            <a routerLink="/components" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Components</a
            >
          </li>
        </ul>
      </div> -->
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h4 class="text-light footer-head">Newsletter</h4>
        <p class="mt-4">Sign up and receive the latest tips via email.</p>
        <form>
          <div class="row">
            <div class="col-lg-12">
              <div class="foot-subscribe form-group position-relative">
                <label
                  >Write your email <span class="text-danger">*</span></label
                >
                <i data-feather="mail" class="fea icon-sm icons"></i>
                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                <input
                  type="email"
                  name="email"
                  id="emailsubscribe"
                  class="form-control pl-5 rounded"
                  placeholder="Your email : "
                  required
                />
              </div>
            </div>
            <div class="col-lg-12">
              <input
                type="submit"
                id="submitsubscribe"
                name="send"
                class="btn btn-soft-primary btn-block"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
<footer class="footer footer-bar">
  <div class="container text-center">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="text-sm-left">
          <p class="mb-0">© 2025 Pedro Luis Pérez Collado.</p>
          <a
            routerLink="/login"
            class="btn btn-primary btn-sm login-btn-primary"
            >Login</a
          >
        </div>
      </div>
      <!--end col-->

      <!-- Payment cards -->

      <!-- <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ul class="list-unstyled text-sm-right mb-0">
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/american-ex.png"
                class="avatar avatar-ex-sm"
                title="American Express"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/discover.png"
                class="avatar avatar-ex-sm"
                title="Discover"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/master-card.png"
                class="avatar avatar-ex-sm"
                title="Master Card"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/paypal.png"
                class="avatar avatar-ex-sm"
                title="Paypal"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/visa.png"
                class="avatar avatar-ex-sm"
                title="Visa"
                alt=""
            /></a>
          </li>
        </ul>
      </div> -->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
<!-- Footer End -->

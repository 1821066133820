<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- COMING SOON PAGE -->
<section
  class="bg-home d-flex align-items-center"
  data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/comingsoon.jpg')"
>
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <a href="javascript:void(0)" class="logo h5"
          ><img src="assets/cover_resized.png" height="50px" alt=""
        /></a>
        <div class="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">
          We're Coming soon...
        </div>
        <p class="text-light para-desc para-dark mx-auto">
          massa eget egestas purus viverra
          <span class="text-primary font-weight-bold">PPA</span> that can Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <div id="countdown">
          <div class="count-down">
            <span class="count-number">{{ _days }}</span
            ><span class="count-head position-relative d-block">Days</span>
          </div>
          <div class="count-down">
            <span class="count-number">{{ _hours }}</span
            ><span class="count-head position-relative d-block">Hours</span>
          </div>
          <div class="count-down">
            <span class="count-number">{{ _minutes }}</span
            ><span class="count-head position-relative d-block">Minutes</span>
          </div>
          <div class="count-down">
            <span class="count-number">{{ _seconds }}</span
            ><span class="count-head position-relative d-block">Seconds</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="./" class="btn btn-primary mt-4"
          ><i class="mdi mdi-backup-restore"></i> Go Back Home</a
        >
      </div>
    </div>
  </div>
  <!-- end container -->
</section>
<!--section end-->
<!-- COMING SOON PAGE -->

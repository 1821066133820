import { Component, OnInit } from "@angular/core";

@Component({
  selector: "post-component",
  // moduleId: module.id,
  templateUrl: "post.component.html",
})
export class PostComponent implements OnInit {
  ngOnInit() {}
}

<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Latest articles</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item">
                  <a routerLink="#">Blog</a>
                </li>
                <li class="breadcrumb-item"><a href="#"></a></li>
                <!-- <li class="breadcrumb-item"><a href="#">Blog</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Blog List with Sidebar
                </li> -->
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!--Blog Lists Start-->
<section class="section">
  <div class="container">
    <div class="row">
      <!-- Blog Post Start -->
      <div class="col-lg-8 col-12">
        <div class="row">
          <div *ngIf="loading" class="d-flex justify-content-center">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>

          <div *ngFor="let post of posts" class="col-12 mb-4 pb-2">
            <div class="card blog rounded border-0 shadow overflow-hidden">
              <div class="row align-items-center no-gutters">
                <div *ngIf="post.className" class="col-md-6">
                  <img
                    src="https://pedro-static-file-storage.s3.amazonaws.com/{{
                      post.pictureUrl
                    }}"
                    class="img-fluid img-full-width"
                    alt=""
                  />
                  <div class="overlay bg-dark"></div>
                  <div class="author">
                    <small class="text-light user d-block"
                      ><i class="mdi mdi-account"></i> Pedro Perez
                      Collado</small
                    >
                    <small class="text-light date"
                      ><i class="mdi mdi-calendar-check"></i>
                      {{ post.createdAt }}</small
                    >
                  </div>
                </div>
                <!--end col-->

                <div *ngIf="post.className" class="col-md-6">
                  <div class="card-body content">
                    <h5>
                      <a
                        href="javascript:void(0)"
                        class="card-title title text-dark"
                        >{{ post.title }}</a
                      >
                    </h5>
                    <p class="text-muted mb-0 angular-with-newlines">
                      {{ post.content }}
                    </p>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0)"
                            class="text-muted comments"
                            ><i class="mdi mdi-comment-outline mr-1"></i
                            >{{ post.commentsNumber }}</a
                          >
                        </li>
                      </ul>
                      <a routerLink="{{ post.id }}" class="text-muted readmore"
                        >Read More <i class="mdi mdi-chevron-right"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <!-- CHANGE THE VALUES TO DISPLAY ON FALSE -->
                <div
                  *ngIf="!post.className"
                  class="col-md-6 order-2 order-md-1"
                >
                  <div class="card-body content">
                    <h5>
                      <a
                        href="javascript:void(0)"
                        class="card-title title text-dark"
                        >{{ post.title }}</a
                      >
                    </h5>
                    <p class="text-muted mb-0">
                      {{ post.content }}
                    </p>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0)"
                            class="text-muted comments"
                          >
                            <i class="mdi mdi-comment-outline mr-1"></i
                            >{{ post.commentsNumber }}
                          </a>
                        </li>
                      </ul>
                      <a routerLink="{{ post.id }}" class="text-muted readmore"
                        >Read More <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div
                  *ngIf="!post.className"
                  class="col-md-6 order-1 order-md-2"
                >
                  <img
                    src="https://pedro-static-file-storage.s3.amazonaws.com/{{
                      post.pictureUrl
                    }}"
                    class="img-fluid img-full-width"
                    alt=""
                  />
                  <div class="overlay bg-dark"></div>
                  <div class="author">
                    <small class="text-light user d-block">
                      <i class="mdi mdi-account"></i> Pedro Perez collado
                    </small>
                    <small class="text-light date">
                      <i class="mdi mdi-calendar-check"></i>{{ post.createdAt }}
                    </small>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end blog post-->
          </div>
          <!--end col-->

          <!-- PASTE IT HERE -->

          <!-- END IT HERE -->

          <!-- PAGINATION START -->
          <div class="col-12">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Previous"
                  >Prev</a
                >
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)" aria-label="Next"
                  >Next</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
      <!-- Blog Post End -->

      <!-- START SIDEBAR -->
      <div class="col-lg-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar sticky-bar rounded shadow">
          <div class="card-body">
            <!-- SEARCH -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Search</h4>
              <div id="search2" class="widget-search mt-4 mb-0">
                <form
                  role="search"
                  method="get"
                  id="searchform"
                  class="searchform"
                >
                  <div>
                    <input
                      type="text"
                      class="border rounded"
                      name="s"
                      id="s"
                      placeholder="Search Keywords..."
                    />
                    <input type="submit" id="searchsubmit" value="Search" />
                  </div>
                </form>
              </div>
            </div>
            <!-- SEARCH -->

            <!-- CATAGORIES -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Catagories</h4>
              <ul class="list-unstyled mt-4 mb-0 blog-catagories">
                <li>
                  <a href="jvascript:void(0)">Finance</a>
                  <span class="float-right">13</span>
                </li>
                <li>
                  <a href="jvascript:void(0)">Business</a>
                  <span class="float-right">09</span>
                </li>
                <li>
                  <a href="jvascript:void(0)">Blog</a>
                  <span class="float-right">18</span>
                </li>
                <li>
                  <a href="jvascript:void(0)">Corporate</a>
                  <span class="float-right">20</span>
                </li>
                <li>
                  <a href="jvascript:void(0)">Investment</a>
                  <span class="float-right">22</span>
                </li>
              </ul>
            </div>
            <!-- CATAGORIES -->

            <!-- RECENT POST -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Recent Post</h4>
              <div class="mt-4">
                <div class="clearfix post-recent">
                  <div class="post-recent-thumb float-left">
                    <a href="jvascript:void(0)">
                      <img
                        alt="img"
                        src="assets/images/blog/07.jpg"
                        class="img-fluid rounded"
                    /></a>
                  </div>
                  <div class="post-recent-content float-left">
                    <a href="jvascript:void(0)">Consultant Business</a
                    ><span class="text-muted mt-2">15th June, 2019</span>
                  </div>
                </div>
                <div class="clearfix post-recent">
                  <div class="post-recent-thumb float-left">
                    <a href="jvascript:void(0)">
                      <img
                        alt="img"
                        src="assets/images/blog/08.jpg"
                        class="img-fluid rounded"
                    /></a>
                  </div>
                  <div class="post-recent-content float-left">
                    <a href="jvascript:void(0)">Look On The Glorious Balance</a>
                    <span class="text-muted mt-2">15th June, 2019</span>
                  </div>
                </div>
                <div class="clearfix post-recent">
                  <div class="post-recent-thumb float-left">
                    <a href="jvascript:void(0)">
                      <img
                        alt="img"
                        src="assets/images/blog/01.jpg"
                        class="img-fluid rounded"
                    /></a>
                  </div>
                  <div class="post-recent-content float-left">
                    <a href="jvascript:void(0)">Research Financial.</a>
                    <span class="text-muted mt-2">15th June, 2019</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- RECENT POST -->

            <!-- TAG CLOUDS -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Tags Cloud</h4>
              <div class="tagcloud mt-4">
                <a href="jvascript:void(0)" class="rounded">Business</a>
                <a href="jvascript:void(0)" class="rounded">Finance</a>
                <a href="jvascript:void(0)" class="rounded">Marketing</a>
                <a href="jvascript:void(0)" class="rounded">Fashion</a>
                <a href="jvascript:void(0)" class="rounded">Bride</a>
                <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                <a href="jvascript:void(0)" class="rounded">Travel</a>
                <a href="jvascript:void(0)" class="rounded">Beauty</a>
                <a href="jvascript:void(0)" class="rounded">Video</a>
                <a href="jvascript:void(0)" class="rounded">Audio</a>
              </div>
            </div>
            <!-- TAG CLOUDS -->

            <!-- SOCIAL -->
            <div class="widget">
              <h4 class="widget-title">Follow us</h4>
              <ul class="list-unstyled social-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="facebook"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="instagram"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="twitter"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="linkedin"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="github"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="youtube"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <i-feather
                      name="gitlab"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
              </ul>
              <!--end icon-->
            </div>
            <!-- SOCIAL -->
          </div>
        </div>
      </div>
      <!--end col-->
      <!-- END SIDEBAR -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section -->
<!--Blog Lists End-->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<!-- Hero Start -->
<section class="bg-half-170 d-table agency-wrapper w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="title-heading mt-4">
          <h1 class="display-4 font-weight-bold mb-3">
            Pedro Luis<br />
            Perez Collado
          </h1>
          <p class="para-desc text-muted">
            pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas integer eget aliquet nibh praesent tristique
            magna
          </p>

          <div class="subcribe-form mt-4 pt-2">
            <a routerLink="/blog" class="btn btn-primary mt-2 mr-2 disabled"
              ><i class="mdi mdi-lead-pencil"></i> My Blog</a
            >
            <!-- <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"
              ><i class="mdi mdi-google-play"></i> Play Store</a
            > -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div
        class="col-lg-5 col-md-5 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
      >
        <img
          src="assets/images/illustrator/working_with_computer.svg"
          class="img-fluid"
          alt=""
        />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h6 class="text-primary">Features</h6>
          <h4 class="title mb-4">Explore awesome features</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            malesuada proin libero
            <span class="text-primary font-weight-bold">$Appname</span> Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-airplay"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Design & Development</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary"
              >Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-clipboard-alt"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Management & Marketing</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary"
              >Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-credit-card-search"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Stratagy & Research</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary"
              >Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-ruler-combined"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Easy To Use</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary">Read More </a>
            <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-exposure-alt"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Daily Reports</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary"
              >Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div
          class="
            card
            features
            explore-feature
            p-4
            px-md-3
            border-0
            rounded-md
            shadow
            text-center
          "
        >
          <div class="icons rounded h2 text-center text-primary px-3">
            <i class="uil uil-clock"></i>
          </div>

          <div class="card-body p-0 content">
            <h5 class="mt-4">
              <a href="javascript:void(0)" class="title text-dark"
                >Real Time Zone</a
              >
            </h5>
            <p class="text-muted">
              The most well-known dummy text is the 'Lorem Ipsum', which is said
              to have originated
            </p>

            <a href="javascript:void(0)" class="text-primary"
              >Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img
          src="assets/images/landing/1.jpg"
          class="img-fluid rounded-md shadow-lg"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title text-md-left text-center ml-lg-4">
          <h4 class="title mb-4">
            Collaborate with your <br />
            team anytime and anywhare.
          </h4>
          <p class="text-muted mb-0 para-desc">
            Start working with
            <span class="text-primary font-weight-bold">$APPNAME</span> Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <div class="media align-items-center text-left mt-4 pt-2">
            <div
              class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow bg-white"
            >
              <i class="uil uil-capture"></i>
            </div>
            <div class="media-body">
              <a href="javascript:void(0)" class="text-dark h6"
                >Find Better Leads</a
              >
            </div>
          </div>

          <div class="media align-items-center text-left mt-4">
            <div
              class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow bg-white"
            >
              <i class="uil uil-file"></i>
            </div>
            <div class="media-body">
              <a href="javascript:void(0)" class="text-dark h6"
                >Set Instant Metting</a
              >
            </div>
          </div>

          <div class="media align-items-center text-left mt-4">
            <div
              class="text-primary h4 mb-0 mr-3 p-3 rounded-md shadow bg-white"
            >
              <i class="uil uil-credit-card-search"></i>
            </div>
            <div class="media-body">
              <a href="javascript:void(0)" class="text-dark h6"
                >Get Paid Seemlessly</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<section class="section">
  <!--end container-->

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Latest entries</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Check out my latest entries
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div *ngFor="let post of posts" class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow">
          <div class="position-relative">
            <img
              src="https://pedro-static-file-storage.s3.amazonaws.com/{{
                post.pictureUrl
              }} "
              class="card-img-top rounded-top img-full-width"
              alt="..."
            />
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="card-body content">
            <h5>
              <a href="javascript:void(0)" class="card-title title text-dark">{{
                post.title
              }}</a>
            </h5>
            <div class="post-meta d-flex justify-content-between mt-3">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="text-muted comments"
                    ><i class="mdi mdi-comment-outline mr-1"></i
                    >{{ post.commentsNumber }}</a
                  >
                </li>
              </ul>
              <a routerLink="/blog/{{ post.id }}" class="text-muted readmore"
                >Read More <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
          <div class="author">
            <small class="text-light user d-block"
              ><i class="mdi mdi-account"></i> Pedro Perez Collado</small
            >
            <small class="text-light date"
              ><i class="mdi mdi-calendar-check"></i>
              {{ post.createdAt }}</small
            >
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- ERROR PAGE -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <img src="assets/images/404.svg" class="img-fluid" alt="" />
        <div class="text-uppercase mt-4 display-3">Oh ! no</div>
        <div class="text-capitalize text-dark mb-4 error-page">
          Page Not Found
        </div>
        <p class="text-muted para-desc mx-auto">
          Start working with
          <span class="text-primary font-weight-bold">PPA</span> that can
          provide everything you need to generate awareness, drive traffic,
          connect.
        </p>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/index" class="btn btn-outline-primary mt-4">Go Back</a>
        <a routerLink="/index" class="btn btn-primary mt-4 ml-2">Go To Home</a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->

<footer class="footer">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="javascript:void()"> Pedro Perez Collado </a>
        </li>
        <li>
          <a href="javascript:void()"> Blog </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy; {{ test | date: "yyyy" }}
      <i class="fa fa-heart heart"></i>
    </div>
  </div>
</footer>

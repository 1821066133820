<div class="row">
  <div class="col-md-4">
    <div class="card card-user">
      <div class="image">
        <img src="assets/damir-bosnjak.jpg" alt="..." />
      </div>
      <div class="card-body">
        <div class="author">
          <a href="javascript:void(0)">
            <img
              class="avatar border-gray"
              src="https://pedro-app-rest-api.herokuapp.com/posts/pictures/{{
                userForm.get('profilePictureName').value
              }}"
              alt="..."
            />
            <h5 class="title">{{ userForm.get("name").value }}</h5>
          </a>
          <p class="description">@{{ userForm.get("userName").value }}</p>
        </div>
        <p class="description text-center">
          "{{ userForm.get("aboutMe").value }}"
        </p>
      </div>
      <div class="card-footer">
        <hr />
        <div class="button-container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6 ml-auto">
              <h5>
                {{ postsNumber }}
                <br />
                <small>Posts</small>
              </h5>
            </div>
            <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
              <h5>
                2GB
                <br />
                <small>Used</small>
              </h5>
            </div>
            <div class="col-lg-3 mr-auto">
              <h5>
                24,6$
                <br />
                <small>Spent</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-user">
      <div class="card-header">
        <h5 class="card-title">Update password</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmitPasswordForm()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Current password</label>
                <input
                  type="password"
                  class="form-control"
                  formControlName="currentPassword"
                  placeholder="Current password"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>New password</label>
                <input
                  type="password"
                  class="form-control"
                  formControlName="newPassword"
                  placeholder="New password"
                />
                <div
                  *ngIf="f.newPassword.touched && f.newPassword.invalid"
                  class="alert alert-primary"
                >
                  <div *ngIf="f.newPassword.errors.required">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Confirm new password</label>
                <input
                  type="password"
                  class="form-control"
                  formControlName="confirmNewPassword"
                  placeholder="Type your new password"
                />
                <div
                  *ngIf="
                    f.confirmNewPassword.touched && f.confirmNewPassword.invalid
                  "
                  class="alert alert-primary"
                >
                  <div *ngIf="f.confirmNewPassword.errors.required">
                    Password is required.
                  </div>
                  <div *ngIf="f.confirmNewPassword.errors.confirmedValidator">
                    New password doesn't match
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button
                type="submit"
                class="btn btn-primary btn-round"
                [disabled]="!passwordForm.valid"
              >
                Update !
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- UPDATE VALUE -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-lg-12">
                <label for="formFile" class="form-label"
                  >Update your profile picture</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  (change)="onFileSelected($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="card card-user">
      <div class="card-header">
        <h5 class="card-title">Edit Profile</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="userForm" (ngSubmit)="onSubmitForm()">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label>Username</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="userName"
                  placeholder="Username"
                  value="michael23"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  formControlName="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder="Name"
                  value="Chet"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>About Me</label>
                <textarea
                  formControlName="aboutMe"
                  id="aboutMeUserInput"
                  class="form-control textarea"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button type="submit" class="btn btn-primary btn-round">
                Update Profile
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="card card-user">
      <div class="image">
        <img src="assets/damir-bosnjak.jpg" alt="..." />
      </div>
      <div class="card-body">
        <div class="author">
          <a href="javascript:void(0)">
            <img
              class="avatar border-gray"
              src="https://pedro-static-file-storage.s3.amazonaws.com/{{
                userForm.get('profilePictureName').value
              }}"
              alt="..."
            />
            <h5 class="title">{{ userForm.get("name").value }}</h5>
          </a>
          <p class="description">@{{ userForm.get("userName").value }}</p>
        </div>
        <p class="description text-center">
          "ut sem nulla pharetra diam
          <br />
          convallis tellus <br />
          feugiat in ante metus"
        </p>
      </div>
      <div class="card-footer">
        <hr />
        <div class="button-container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6 ml-auto">
              <h5>
                {{ postsNumber }}
                <br />
                <small>Posts</small>
              </h5>
            </div>
            <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
              <h5>
                2GB
                <br />
                <small>Used</small>
              </h5>
            </div>
            <div class="col-lg-3 mr-auto">
              <h5>
                24,6$
                <br />
                <small>Spent</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-header">
        <h4 class="card-title">Team Members</h4>
      </div>
      <div class="card-body">
        <ul class="list-unstyled team-members">
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-md-7 col-7">
                DJ Khaled
                <br />
                <span class="text-muted">
                  <small>Offline</small>
                </span>
              </div>
              <div class="col-md-3 col-3 text-right">
                <button class="btn btn-sm btn-outline-success btn-round btn-icon"><i class="fa fa-envelope"></i></button>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/joe-gardner-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-md-7 col-7">
                Creative Tim
                <br />
                <span class="text-success">
                  <small>Available</small>
                </span>
              </div>
              <div class="col-md-3 col-3 text-right">
                <button class="btn btn-sm btn-outline-success btn-round btn-icon"><i class="fa fa-envelope"></i></button>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/clem-onojeghuo-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-ms-7 col-7">
                Flume
                <br />
                <span class="text-danger">
                  <small>Busy</small>
                </span>
              </div>
              <div class="col-md-3 col-3 text-right">
                <button class="btn btn-sm btn-outline-success btn-round btn-icon"><i class="fa fa-envelope"></i></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- UPDATE VALUE -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-lg-12">
                <label for="formFile" class="form-label"
                  >Update your profile picture</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  (change)="onFileSelected($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="card card-user">
      <div class="card-header">
        <h5 class="card-title">Edit Profile</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="userForm" (ngSubmit)="onSubmitForm()">
          <div class="row">
            <div class="col-md-5 pr-1">
              <div class="form-group">
                <label>Username</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="userName"
                  placeholder="Username"
                  value="michael23"
                />
              </div>
            </div>
            <div class="col-md-7 pl-1">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  formControlName="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pr-1">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder="Name"
                  value="Chet"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>About Me</label>
                <textarea
                  formControlName="aboutMe"
                  id="aboutMeUserInput"
                  class="form-control textarea"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button type="submit" class="btn btn-primary btn-round">
                Update Profile
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="sidebar-wrapper">
  <div class="logo">
    <a href="javascript:void(0)" class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="assets/dashboard_logo.png" />
      </div>
    </a>
    <a href="javascript:void(0)" class="simple-text logo-normal">
      Pedro Perez
    </a>
  </div>
  <ul class="nav">
    <li
      *ngFor="let menuItem of menuItems"
      routerLinkActive="active"
      class="{{ menuItem.class }}"
    >
      <a [routerLink]="[menuItem.path]">
        <i class="nc-icon {{ menuItem.icon }}"></i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
  </ul>
</div>

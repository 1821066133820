<section class="bg-light d-table w-100 pt-3 mr-0" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="card blog blog-detail border-0 shadow rounded">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <img
                src="https://pedro-static-file-storage.s3.amazonaws.com/{{
                  post.pictureName
                }}"
                class="img-fluid rounded-top w-50"
                alt=""
                id="viewPostPicture"
              />
            </div>
          </div>

          <div class="card-body mt-0">
            <h4 class="card-title">{{ post.title }}</h4>
            <!-- <h6>
              <i class="mdi mdi-tag text-primary mr-1"></i
              ><a href="javscript:void(0)" class="text-primary">Software</a>,
              <a href="javscript:void(0)" class="text-primary">Application</a>
            </h6> -->
            <p class="text-muted mt-3 angular-with-newlines">
              {{ post.content }}
            </p>
            <!-- <blockquote class="blockquote mt-3 p-3">
                        <p class="text-muted mb-0 font-italic">
                          " There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration in some
                          form, by injected humour, or randomised words which don't look
                          even slightly believable. "
                        </p>
                      </blockquote>
                      <p class="text-muted">
                        The advantage of its Latin origin and the relative meaninglessness
                        of Lorum Ipsum is that the text does not attract attention to
                        itself or distract the viewer's attention from the layout.
                      </p> -->
            <div class="post-meta mt-3">
              <ul class="list-unstyled mb-0">
                <!-- <li class="list-inline-item mr-2">
                            <a href="javascript:void(0)" class="text-muted like"
                              ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                            >
                          </li> -->
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="text-muted comments"
                    ><i class="mdi mdi-comment-outline mr-1"></i
                    >{{ post.comments.length }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Comments :</h5>

            <ul class="media-list list-unstyled mb-0">
              <li class="mt-4" *ngFor="let comment of post.comments">
                <div class="d-flex justify-content-between">
                  <div class="media align-items-center">
                    <a class="pr-3" href="#">
                      <!-- <img
                          src="assets/images/client/01.jpg"
                          class="
                            img-fluid
                            avatar avatar-md-sm
                            rounded-circle
                            shadow
                          "
                          alt="img"
                        /> -->
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0">
                        <a class="text-dark media-heading">{{
                          comment.userName
                        }}</a>
                      </h6>
                      <small class="text-muted">{{ comment.createdAt }}</small>
                    </div>
                  </div>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <button
                        (click)="deleteComment(comment.id, comment.userName)"
                        class="btn btn-outline-danger px-2 py-1"
                      >
                        <i
                          class="mdi mdi-delete-circle"
                          style="font-size: 25px"
                        ></i>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="mt-3">
                  <p class="text-muted font-italic p-3 bg-light rounded">
                    {{ comment.comment }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
